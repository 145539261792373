import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Avatar,
  Divider
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { clearUser, setUser } from '../redux/userSlice';

const Navbar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [userFetched, setUserFetched] = useState(false);
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    dispatch(clearUser());
    localStorage.removeItem('token'); // Clear token on logout
  };

  useEffect(() => {
    if (isAuthenticated && !userFetched) {
      const fetchUserData = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await fetch('https://testdbaac.dodaydream.net/api/employees/me', {
            headers: {
              'x-access-token': token,
            },
          });
          if (response.ok) {
            const userData = await response.json();
            dispatch(setUser({ user: userData, token })); // Update user state in Redux
            setUserFetched(true);
          } else {
            console.error('Failed to fetch user data:', response.statusText);
            dispatch(clearUser()); // Clear user data if fetching failed
          }
        } catch (error) {
          console.error('Failed to fetch user data:', error);
          dispatch(clearUser());
        }
      };
      fetchUserData();
    }
  }, [isAuthenticated, userFetched, dispatch]);

  const avatarSrc = user?.avatarUrl || '/path/to/demo-avatar.jpg';

  return (
    <>
      <AppBar position="static" translate="no">
        <Toolbar translate="no">
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            translate="no"
          >
            <MenuIcon />
          </IconButton>
          <Drawer
            anchor="left"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            translate="no"
          >
            <Box
              sx={{ width: 250 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
              translate="no"
            >
              {isAuthenticated && user ? (
                <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                  <Avatar alt={`${user.firstname} ${user.lastname}`} src={avatarSrc} sx={{ mr: 2 }} />
                  <Box>
                    <Typography variant="subtitle1">{user.firstname} {user.lastname}</Typography>
                    <Typography variant="body2" color="textSecondary">{user.email}</Typography>
                  </Box>
                </Box>
              ) : null}

              <Divider />

              <List>
                {[{ text: 'Home', path: '/' }, { text: 'รายการรอตรวจนับ', path: '/nocheck' }, { text: 'ตรวจนับเรียบร้อย', path: '/yescheck' }, { text: 'Add Asset', path: '/add-asset' }, { text: 'My Account', path: '/my-account' }]
                  .map((item) => (
                    <ListItem button key={item.text} component={Link} to={item.path} translate="no">
                      <ListItemText primary={item.text} />
                    </ListItem>
                  ))}

                {/* แสดง HR Core เฉพาะ Superadmin */}
                {user && user.role === 'Superadmin' && (
                  <ListItem button component={Link} to="/settings/users" translate="no">
                    <ListItemText primary="Hr Core" />
                  </ListItem>
                )}
              </List>

              {isAuthenticated && (
                <>
                  <Divider />
                  <ListItem button onClick={handleLogout} translate="no">
                    <ListItemText primary="Logout" />
                  </ListItem>
                </>
              )}
            </Box>
          </Drawer>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: 'center' }}
            translate="no"
          >
            {isAuthenticated && user ? `Hello, ${user.firstname} ${user.lastname}!` : 'Hello, Guest!'}
          </Typography>

          {isAuthenticated && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              component={Link}
              to="/add-asset"
              sx={{ marginRight: 1 }}
              translate="no"
            >
              Assets
            </Button>
          )}

          {!isAuthenticated && (
            <>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/login"
                sx={{ marginRight: 1 }}
                translate="no"
              >
                Login
              </Button>
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/signup"
                translate="no"
              >
                Signup
              </Button>
            </>
          )}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
