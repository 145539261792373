import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Paper,
  Typography,
  TextField,
  Button,
  Grid,
  MenuItem,
  Alert,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const AddAsset = () => {
  const [asset, setAsset] = useState({
    Company: '',
    Assetname: '',
    Inventory_ID: '',
    Site: '',
    Sub_Location: '',
    Sub_Loc_Name: '',
    Serial_No: '',
    Note: '',
    Picture_11: '',
    Picture_21: '',
    STATUS: '',
    Employee_Responsible: '',
    Department_Responsible: '',
    Qty: '1',
    Check_By_AC: 'No',
    Inventory_ID_OLD: 'nan',
  });

  const [companies, setCompanies] = useState([]);
  const [employeeResponsibles, setEmployeeResponsibles] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [siteOptions, setSiteOptions] = useState([]);
  const [subLocationOptions, setSubLocationOptions] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [error, setError] = useState('');
  const [fieldErrors, setFieldErrors] = useState({});
  const [previewOpen, setPreviewOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [picture1Preview, setPicture1Preview] = useState(null);
  const [picture2Preview, setPicture2Preview] = useState(null);
  const navigate = useNavigate();

  // ฟังก์ชันดึงข้อมูล API พร้อมกับ Token
  const fetchWithToken = async (url, options = {}) => {
    const token = localStorage.getItem('token');
    if (!token) throw new Error('Authentication token is missing.');

    const headers = {
      Authorization: `Bearer ${token}`,
      ...options.headers,
    };

    const response = await fetch(url, { ...options, headers });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || 'Failed to fetch data');
    }
    return await response.json();
  };

  // ดึงข้อมูล API หลายชุดพร้อมกัน
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [
          companiesData,
          employeeResponsiblesData,
          departmentsData,
          sitesData,
        ] = await Promise.all([
          fetchWithToken('https://testdbaac.dodaydream.net/api/companies'),
          fetchWithToken('https://testdbaac.dodaydream.net/api/employee_responsibles'),
          fetchWithToken('https://testdbaac.dodaydream.net/api/department_responsibles'),
          fetchWithToken('https://testdbaac.dodaydream.net/api/sites'),
        ]);

        setCompanies(companiesData);
        setEmployeeResponsibles(employeeResponsiblesData);
        setDepartments(departmentsData);
        setSiteOptions(sitesData);
      } catch (error) {
        console.error('Error fetching initial data:', error);
        setError(error.message || 'Error fetching initial data.');
      }
    };

    fetchInitialData();
  }, []);

  // ฟังก์ชันช่วยเหลือในการแยกสตริงเป็น sub_location และ sub_loc_name
  const splitSubLocation = (fullString) => {
    const firstSpaceIndex = fullString.indexOf(' ');
    if (firstSpaceIndex === -1) {
      return { sub_location: fullString, sub_loc_name: '' };
    }
    const sub_location = fullString.substring(0, firstSpaceIndex);
    const sub_loc_name = fullString.substring(firstSpaceIndex + 1);
    return { sub_location, sub_loc_name };
  };

  // ดึงข้อมูล Sub Location และ Sub Loc Name ตาม Site ที่เลือก
  useEffect(() => {
    const fetchFilteredData = async () => {
      if (!asset.Site) {
        setSubLocationOptions([]);
        setAsset((prevAsset) => ({
          ...prevAsset,
          Sub_Location: '',
          Sub_Loc_Name: '',
        }));
        return;
      }

      try {
        const subLocationsWithNames = await fetchWithToken(
          `https://testdbaac.dodaydream.net/api/sub_locations_with_names?site=${encodeURIComponent(asset.Site)}`
        );
        console.log('Sub Locations with Names:', subLocationsWithNames); // ตรวจสอบข้อมูลที่ได้รับ

        // ตั้งค่า subLocationOptions เป็นอาร์เรย์ของสตริง
        setSubLocationOptions(subLocationsWithNames);
        setAsset((prevAsset) => ({
          ...prevAsset,
          Sub_Location: '',
          Sub_Loc_Name: '',
        }));
      } catch (error) {
        console.error('Error fetching sub locations:', error);
        setSubLocationOptions([]);
        setError(error.message || 'Error fetching sub locations.');
      }
    };

    fetchFilteredData();
  }, [asset.Site]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAsset({ ...asset, [name]: value });
  };

  const handlePicture1Change = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('File must be an image');
        return;
      }
      if (file.size > 5 * 1024 * 1024) { // Limit 5MB
        setError('File size must be less than 5MB');
        return;
      }
      setAsset({ ...asset, Picture_11: file });
      setPicture1Preview(URL.createObjectURL(file));
    }
  };

  const handlePicture2Change = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.startsWith('image/')) {
        setError('File must be an image');
        return;
      }
      if (file.size > 5 * 1024 * 1024) { // Limit 5MB
        setError('File size must be less than 5MB');
        return;
      }
      setAsset({ ...asset, Picture_21: file });
      setPicture2Preview(URL.createObjectURL(file));
    }
  };

  const validateFields = () => {
    const errors = {};
    const requiredFields = ['Assetname', 'Inventory_ID', 'Site', 'Sub_Location', 'Sub_Loc_Name'];

    requiredFields.forEach((field) => {
      if (!asset[field]) errors[field] = 'This field is required';
    });

    if (asset.Inventory_ID && asset.Inventory_ID.length < 5) {
      errors.Inventory_ID = 'Inventory ID ต้องยาวอย่างน้อย 5 ตัวอักษร';
    }

    return errors;
  };

  const handleSave = async () => {
    setError(''); // Clear previous errors
    const validationErrors = validateFields();

    // Validate required fields
    if (Object.keys(validationErrors).length > 0) {
      setFieldErrors(validationErrors);
      setError('Please fill in all required fields.');
      return;
    }

    const formData = new FormData();

    // Append all fields except Picture_11 and Picture_21
    Object.keys(asset).forEach((key) => {
      if (
        asset[key] !== null &&
        asset[key] !== undefined &&
        key !== 'Picture_11' &&
        key !== 'Picture_21'
      ) {
        // ตรวจสอบชื่อฟิลด์ที่ backend ต้องการ
        // ถ้า backend ต้องการ 'sub_location' และ 'sub_loc_name', ให้ส่งชื่อฟิลด์นั้น
        if (key === 'Sub_Location') {
          formData.append('sub_location', asset[key]);
        } else if (key === 'Sub_Loc_Name') {
          formData.append('sub_loc_name', asset[key]);
        } else {
          formData.append(key, asset[key]);
        }
      }
    });

    // Handle file uploads for Picture_11 and Picture_21
    if (asset.Picture_11 instanceof File) {
      formData.append('Picture_11', asset.Picture_11);
    }
    if (asset.Picture_21 instanceof File) {
      formData.append('Picture_21', asset.Picture_21);
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('Authentication token is missing.');

      const response = await fetch('https://testdbaac.dodaydream.net/api/fixed_assets', {
        method: 'POST',
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to add asset');
      }

      setSuccess(true); // Show success message
      setTimeout(() => navigate('/'), 2000); // Redirect after success
    } catch (error) {
      console.error('Error:', error);
      setError(error.message || 'An error occurred while saving the asset.');
    }
  };

  const handlePreview = () => {
    setPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setPreviewOpen(false);
  };

  // เพิ่มการตรวจสอบค่าที่ถูกตั้งค่าใน state
  useEffect(() => {
    console.log('Current Asset State:', asset);
  }, [asset]);

  return (
    <Paper style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h4" gutterBottom>
        Add New Asset
      </Typography>
      {error && <Alert severity="error" onClose={() => setError('')}>{error}</Alert>}
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Inventory ID"
            name="Inventory_ID"
            value={asset.Inventory_ID}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
            error={!!fieldErrors.Inventory_ID}
            helperText={fieldErrors.Inventory_ID}
          />

          <TextField
            label="Asset Name"
            name="Assetname"
            value={asset.Assetname}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            required
            error={!!fieldErrors.Assetname}
            helperText={fieldErrors.Assetname}
          />

          <TextField
            label="Serial No"
            name="Serial_No"
            value={asset.Serial_No}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />

          <TextField
            label="Note"
            name="Note"
            value={asset.Note}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          {/* Autocomplete สำหรับ Company */}
          <Autocomplete
            options={companies}
            getOptionLabel={(option) => `${option.orgname} (${option.orgname_th})`}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company"
                fullWidth
                margin="normal"
                required
                error={!!fieldErrors.Company}
                helperText={fieldErrors.Company}
              />
            )}
            value={selectedCompany}
            onChange={(event, newValue) => {
              setSelectedCompany(newValue);
              setAsset({
                ...asset,
                Company: newValue ? newValue.company_id : '',
              });
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.company_id}>
                {option.orgname} ({option.orgname_th})
              </li>
            )}
          />

          <Autocomplete
            options={siteOptions}
            getOptionLabel={(option) => option}
            value={asset.Site}
            onChange={(event, newValue) => {
              setAsset({ 
                ...asset, 
                Site: newValue || '', 
                Sub_Location: '',
                Sub_Loc_Name: '',
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Site"
                fullWidth
                margin="normal"
                required
                error={!!fieldErrors.Site}
                helperText={fieldErrors.Site}
              />
            )}
          />

          <Autocomplete
            options={subLocationOptions} // ตัวเลือก Sub Location ที่ถูกกรองแล้ว
            getOptionLabel={(option) => option} // ใช้ฟิลด์ที่ถูกต้อง (สตริง)
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            value={asset.Sub_Location && asset.Sub_Loc_Name ? `${asset.Sub_Location} ${asset.Sub_Loc_Name}` : null}
            onChange={(event, newValue) => {
              if (newValue) {
                console.log('Selected Sub Location:', newValue);
                const { sub_location, sub_loc_name } = splitSubLocation(newValue);
                setAsset({
                  ...asset,
                  Sub_Location: sub_location,
                  Sub_Loc_Name: sub_loc_name,
                });
              } else {
                setAsset({
                  ...asset,
                  Sub_Location: '',
                  Sub_Loc_Name: '',
                });
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Sub Location + Sub Loc Name"
                fullWidth
                margin="normal"
                required
                error={!!fieldErrors.Sub_Location || !!fieldErrors.Sub_Loc_Name}
                helperText={fieldErrors.Sub_Location || fieldErrors.Sub_Loc_Name}
              />
            )}
          />

          <Autocomplete
            options={employeeResponsibles}
            getOptionLabel={(option) => option || ''}
            value={asset.Employee_Responsible || ''}
            onChange={(event, newValue) => {
              setAsset({
                ...asset,
                Employee_Responsible: newValue || '',
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Employee Responsible"
                margin="normal"
                fullWidth
              />
            )}
          />

          <Autocomplete
            options={departments}
            getOptionLabel={(option) => option || ''}
            value={asset.Department_Responsible || ''}
            onChange={(event, newValue) => {
              setAsset({
                ...asset,
                Department_Responsible: newValue || '',
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Department Responsible"
                margin="normal"
                fullWidth
              />
            )}
          />

          <Typography variant="h6" style={{ marginTop: '16px' }}>Picture 1</Typography>
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
            style={{ marginTop: '8px' }}
          >
            Upload Picture 1
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handlePicture1Change}
            />
          </Button>
          {picture1Preview && (
            <div>
              <img src={picture1Preview} alt="Picture 1 Preview" style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '8px' }} />
            </div>
          )}

          <Typography variant="h6" style={{ marginTop: '16px' }}>Picture 2</Typography>
          <Button
            variant="contained"
            component="label"
            startIcon={<CloudUploadIcon />}
            style={{ marginTop: '8px' }}
          >
            Upload Picture 2
            <input
              type="file"
              accept="image/*"
              hidden
              onChange={handlePicture2Change}
            />
          </Button>
          {picture2Preview && (
            <div>
              <img src={picture2Preview} alt="Picture 2 Preview" style={{ maxWidth: '100%', maxHeight: '200px', marginTop: '8px' }} />
            </div>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Status Asset"
            name="STATUS"
            select
            value={asset.STATUS}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          >
            <MenuItem value="ใช้งานปกติ">ใช้งานปกติ</MenuItem>
            <MenuItem value="ไม่ได้ใช้งาน">ไม่ได้ใช้งาน</MenuItem>
            <MenuItem value="ชำรุด/เสื่อมสภาพ">ชำรุด/เสื่อมสภาพ</MenuItem>
            <MenuItem value="ไม่พบทรัพย์สิน">ไม่พบทรัพย์สิน</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePreview}
            style={{ marginTop: '16px', marginRight: '16px' }}
          >
            Preview Changes
          </Button>

          <Button variant="contained" color="primary" onClick={handleSave} style={{ marginTop: '16px' }}>
            Save Asset
          </Button>

          <Dialog open={previewOpen} onClose={handlePreviewClose}>
            <DialogTitle>Preview Changes</DialogTitle>
            <DialogContent>
              <Typography variant="h6">Company: {asset.Company}</Typography>
              <Typography variant="h6">Site: {asset.Site}</Typography>
              <Typography variant="h6">Sub Location + Sub Loc Name: {asset.Sub_Location} - {asset.Sub_Loc_Name}</Typography>
              <Typography variant="h6">Department Responsible: {asset.Department_Responsible}</Typography>
              <Typography variant="h6">Employee Responsible: {asset.Employee_Responsible}</Typography>
              <Typography variant="h6">Asset Name: {asset.Assetname}</Typography>
              <Typography variant="h6">Serial No: {asset.Serial_No}</Typography>
              <Typography variant="h6">Note: {asset.Note}</Typography>
              {picture1Preview && (
                <div>
                  <Typography variant="h6">Picture 1 Preview:</Typography>
                  <img src={picture1Preview} alt="Picture 1 Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                </div>
              )}
              {picture2Preview && (
                <div>
                  <Typography variant="h6">Picture 2 Preview:</Typography>
                  <img src={picture2Preview} alt="Picture 2 Preview" style={{ maxWidth: '100%', maxHeight: '200px' }} />
                </div>
              )}
              <Typography variant="h6">Status Asset: {asset.STATUS}</Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handlePreviewClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>

          {/* Snackbar for success message */}
          <Snackbar
            open={success}
            onClose={() => setSuccess(false)}
            message="Asset saved successfully!"
            autoHideDuration={4000}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddAsset;
