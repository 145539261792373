// src/components/EditUser.js
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Paper, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const EditUser = () => {
  const { id } = useParams(); // Get user ID from URL
  const [user, setUser] = useState({
    username: '',
    email: '',
    firstname: '',
    lastname: '',
    department: '',
    role: '',
    password: '', // for new users
  });
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setIsEdit(true);
      const fetchUser = async () => {
        try {
          const response = await fetch(`https://testdbaac.dodaydream.net/api/users/${id}`);
          if (!response.ok) throw new Error('Failed to fetch user');
          const data = await response.json();
          setUser(data);
        } catch (error) {
          console.error('Error:', error);
        }
      };
      fetchUser();
    }
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };

  const handleSave = async () => {
    try {
      const method = isEdit ? 'PUT' : 'POST';
      const endpoint = isEdit ? `https://testdbaac.dodaydream.net/api/users/${id}` : 'https://testdbaac.dodaydream.net/api/users';

      const response = await fetch(endpoint, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      if (!response.ok) {
        throw new Error('Failed to save user');
      }

      navigate('/settings/users');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Paper style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h4" gutterBottom>
        {isEdit ? 'Edit User' : 'Add New User'}
      </Typography>
      <form noValidate autoComplete="off">
        <TextField
          label="Username"
          name="username"
          value={user.username}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Email"
          name="email"
          value={user.email}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="First Name"
          name="firstname"
          value={user.firstname}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Last Name"
          name="lastname"
          value={user.lastname}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <TextField
          label="Department"
          name="department"
          value={user.department}
          onChange={handleInputChange}
          fullWidth
          margin="normal"
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Role</InputLabel>
          <Select
            name="role"
            value={user.role}
            onChange={handleInputChange}
          >
            <MenuItem value="superadmin">Super Admin</MenuItem>
            <MenuItem value="admin">Admin</MenuItem>
            <MenuItem value="audit">Audit</MenuItem>
            <MenuItem value="user">User</MenuItem>
          </Select>
        </FormControl>
        {!isEdit && (
          <TextField
            label="Password"
            name="password"
            type="password"
            value={user.password}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          style={{ marginTop: '16px' }}
        >
          {isEdit ? 'Save Changes' : 'Create User'}
        </Button>
      </form>
    </Paper>
  );
};

export default EditUser;
