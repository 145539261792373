import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Alert } from '@mui/material';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setMessage(null);
    
    try {
      const response = await fetch('https://testdbaac.dodaydream.net/api/forgot-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage('กรุณาตรวจสอบอีเมลของคุณเพื่อรีเซ็ตพาสเวิร์ด');
      } else {
        setError('ไม่พบอีเมลในระบบ');
      }
    } catch (error) {
      console.error('Error:', error);
      setError('เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง');
    }
  };

  return (
    <Container maxWidth="xs">
      <Typography variant="h4" gutterBottom>Forgot Password</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Email"
          type="email"
          fullWidth
          margin="normal"
          required
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {error && <Alert severity="error">{error}</Alert>}
        {message && <Alert severity="success">{message}</Alert>}
        <Button variant="contained" color="primary" type="submit" fullWidth style={{ marginTop: '16px' }}>
          ส่งลิงก์รีเซ็ตพาสเวิร์ด
        </Button>
      </form>
    </Container>
  );
};

export default ForgotPassword;
