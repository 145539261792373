import React, { useState, useEffect } from 'react';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableContainer,
  Typography,
  CircularProgress,
  Box,
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const UserManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading indicator
  const { user } = useSelector((state) => state.user); // Get the current logged-in user from Redux state
  const navigate = useNavigate();

  // Fetch employees from API
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token = localStorage.getItem('token'); // Get the token from local storage
        const response = await fetch('https://testdbaac.dodaydream.net/api/employees', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (!response.ok) throw new Error('Failed to fetch employees');
        const data = await response.json();
        setEmployees(data);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false); // Stop loading once the data is fetched
      }
    };

    fetchEmployees();
  }, []);

  // Handle employee edit action
  const handleEdit = (id) => {
    if (user.role !== 'Superadmin') {
      alert('Only Superadmin can edit employee details!');
      return;
    }
    navigate(`/settings/employees/edit/${id}`);
  };

  // Handle employee delete action
  const handleDelete = async (id) => {
    if (user.role !== 'Superadmin') {
      alert('Only Superadmin can delete employees!');
      return;
    }

    if (window.confirm('คุณแน่ใจหรือไม่ว่าต้องการลบพนักงานคนนี้?')) {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`https://testdbaac.dodaydream.net/api/employees/${id}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          setEmployees(employees.filter((employee) => employee.id !== id));
        } else {
          console.error('Failed to delete employee');
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h4" gutterBottom>Employee Management</Typography>
      {user.role === 'Superadmin' && (
        <Button variant="contained" color="primary" onClick={() => navigate('/settings/employees/add')}>
          เพิ่มพนักงานใหม่
        </Button>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>รหัสพนักงาน</TableCell>
              <TableCell>ชื่อ (TH)</TableCell>
              <TableCell>นามสกุล (TH)</TableCell>
              <TableCell>ชื่อเล่น (TH)</TableCell>
              <TableCell>ชื่อ (EN)</TableCell>
              <TableCell>นามสกุล (EN)</TableCell>
              <TableCell>emp_id</TableCell>
              <TableCell>บริษัท</TableCell>
              <TableCell>FUNC</TableCell>
              <TableCell>DIVI</TableCell>
              <TableCell>SECT</TableCell>
              <TableCell>TEAM</TableCell>
              <TableCell>Subteam</TableCell>
              <TableCell>Subteam1</TableCell>
              <TableCell>ตำแหน่ง</TableCell>
              <TableCell>อีเมล</TableCell>
              <TableCell>username</TableCell>
              <TableCell>login_enable</TableCell>
              <TableCell>createdate</TableCell>
              <TableCell>updatedate</TableCell>
              <TableCell>role_id</TableCell>
              <TableCell>รูปโปรไฟล์</TableCell>
              <TableCell>การจัดการ</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.map((emp) => (
              <TableRow key={emp.id}>
                <TableCell>{emp.emp_no}</TableCell>
                <TableCell>{emp.firstname_th}</TableCell>
                <TableCell>{emp.lastname_th}</TableCell>
                <TableCell>{emp.nickname_th}</TableCell>
                <TableCell>{emp.firstname}</TableCell>
                <TableCell>{emp.lastname}</TableCell>
                <TableCell>{emp.emp_id}</TableCell>
                <TableCell>{emp.company_id}</TableCell>
                <TableCell>{emp.FUNC}</TableCell>
                <TableCell>{emp.DIVI}</TableCell>
                <TableCell>{emp.SECT}</TableCell>
                <TableCell>{emp.TEAM}</TableCell>
                <TableCell>{emp.Subteam}</TableCell>
                <TableCell>{emp.Subteam1}</TableCell>
                <TableCell>{emp.position}</TableCell>
                <TableCell>{emp.email}</TableCell>
                <TableCell>{emp.username}</TableCell>
                <TableCell>{emp.login_enable ? 'Enabled' : 'Disabled'}</TableCell>
                <TableCell>{emp.createdate}</TableCell>
                <TableCell>{emp.updatedate}</TableCell>
                <TableCell>{emp.role_id}</TableCell>
                <TableCell>
                  {emp.pro_img ? <img src={emp.pro_img} alt="Profile" width="50" /> : 'No Image'}
                </TableCell>
                <TableCell>
                  <IconButton onClick={() => handleEdit(emp.id)} disabled={user.role !== 'Superadmin'}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(emp.id)} disabled={user.role !== 'Superadmin'}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default UserManagement;
