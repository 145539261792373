import React, { useEffect, useState } from 'react';
import { Paper, Typography, TextField, Button, Grid, CircularProgress, Alert } from '@mui/material';
import axios from 'axios';

const MyAccount = () => {
  const [user, setUser] = useState({
    id: '',
    emp_no: '',
    firstname_th: '',
    lastname_th: '',
    nickname_th: '',
    firstname: '',
    lastname: '',
    emp_id: '',
    company_id: '',
    FUNC: '', // Department (FUNC)
    DIVI: '', // Division (DIVI)
    SECT: '', // Section (SECT)
    TEAM: '', // Team (TEAM)
    Subteam: '', // Subteam (Subteam)
    Subteam1: '', // Subteam1 (Subteam1)
    position: '', // Position (position)
    email: '', // Employee email
    username: '', // Username
    password: '', // Password (hidden in UI)
    login_enable: '', // Login enabled/disabled
    createdate: '', // Creation date
    updatedate: '', // Last updated date
    role: '', // Role name (from roles table)
    pro_img: '', // Profile image (URL)
    // Company information
    org: '',
    orgname: '',
    orgname_th: '',
    address: '',
    phone: '',
    company_email: '',
    tax_no: '',
    map: '',
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState('');
  const [uploading, setUploading] = useState(false);

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [passwordSuccess, setPasswordSuccess] = useState('');

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false);

  // Fetch user details from backend API
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const response = await axios.get('https://testdbaac.dodaydream.net/api/employees/me', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        setUser(response.data); // Set user data from the API response, including company info
        setImagePreview(response.data.pro_img); // Set profile image preview
      } catch (err) {
        console.error('Failed to load user details', err);
        setError('Failed to load user details');
      } finally {
        setLoading(false);
      }
    };
    fetchUserDetails();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      setImagePreview(URL.createObjectURL(file)); // Show image preview
    }
  };

  const handleUpload = async () => {
    if (!selectedImage) return;

    setUploading(true);
    try {
      const formData = new FormData();
      formData.append('profileImage', selectedImage);

      const token = localStorage.getItem('token');
      const response = await axios.post('https://testdbaac.dodaydream.net/api/employees/upload-profile-picture', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      setImagePreview(response.data.imagePath); // Set the uploaded image path as previ
      setUser((prev) => ({ ...prev, pro_img: response.data.imagePath }));
    } catch (err) {
      console.error('Failed to upload profile picture:', err);
      setError('Failed to upload profile picture. Please try again.');
    } finally {
      setUploading(false);
    }
  };

  const handleUpdate = async () => {
    const updateData = { ...user };
    
    
    if (!selectedImage) {
        delete updateData.pro_img;
    }
    
    setUpdateLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.put(`https://testdbaac.dodaydream.net/api/employee/update/${user.id}`, updateData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        }
      });
      setMessage('Profile updated successfully');
    } catch (err) {
      console.error('Failed to update profile:', err.response ? err.response.data : err.message);
      setError('Failed to update profile');
    } finally {
      setUpdateLoading(false);
    }
};

  const handlePasswordChange = async () => {
    if (password !== confirmPassword) {
      setPasswordError('Passwords do not match');
      return;
    }

    setPasswordLoading(true);
    try {
      const token = localStorage.getItem('token');
      await axios.put(`https://testdbaac.dodaydream.net/api/employees/${user.id}/password`, {
        currentPassword,
        newPassword: password,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setPasswordSuccess('Password changed successfully');
      setPasswordError('');
      setPassword('');
      setConfirmPassword('');
      setCurrentPassword('');
    } catch (err) {
      console.error('Failed to change password:', err.response ? err.response.data : err.message);
      setPasswordError('Failed to change password. Please try again.');
      setPasswordSuccess('');
    } finally {
      setPasswordLoading(false);
    }
  };

  if (loading) return <CircularProgress />;

  if (error) return <Alert severity="error">{error}</Alert>;

  return (
    <Paper style={{ padding: '16px', margin: '16px' }}>
      <Typography variant="h4" gutterBottom>
        My Account
      </Typography>
      <Grid container spacing={2}>
        {/* Profile Picture Section */}
        <Grid item xs={12}>
          <Typography variant="h6">Profile Picture</Typography>
          {imagePreview && <img src={imagePreview} alt="Profile" style={{ width: '150px', height: '150px', objectFit: 'cover', borderRadius: '50%' }} />}
          <input type="file" onChange={handleImageChange} />
          <Button variant="contained" color="primary" onClick={handleUpload} disabled={uploading}>
            {uploading ? <CircularProgress size={24} /> : 'Upload Image'}
          </Button>
        </Grid>

        {/* Display Employee Info */}
        <Grid item xs={12} sm={6}>
          <TextField label="Employee Number" name="emp_no" value={user.emp_no} onChange={handleChange} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Username" name="username" value={user.username} onChange={handleChange} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="First Name (Thai)" name="firstname_th" value={user.firstname_th} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Last Name (Thai)" name="lastname_th" value={user.lastname_th} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="First Name (English)" name="firstname" value={user.firstname} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Last Name (English)" name="lastname" value={user.lastname} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Department (FUNC)" name="FUNC" value={user.FUNC} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Position" name="position" value={user.position} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Email" name="email" value={user.email} onChange={handleChange} fullWidth />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Role" name="role" value={user.role} fullWidth disabled />
        </Grid>

        {/* Display Company Info */}
        <Grid item xs={12} sm={6}>
          <TextField label="Company (Org)" name="org" value={user.org} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Company Name (English)" name="orgname" value={user.orgname} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Company Name (Thai)" name="orgname_th" value={user.orgname_th} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Company Address" name="address" value={user.address} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Company Phone" name="phone" value={user.phone} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Company Email" name="company_email" value={user.company_email} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Company Tax No." name="tax_no" value={user.tax_no} fullWidth disabled />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField label="Company Map URL" name="map" value={user.map} fullWidth disabled />
        </Grid>

        {/* Update Profile Button */}
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleUpdate}
            disabled={updateLoading}
          >
            {updateLoading ? <CircularProgress size={24} /> : 'Update Profile'}
          </Button>
        </Grid>
        {message && <Grid item xs={12}><Alert severity="success">{message}</Alert></Grid>}
        {error && <Grid item xs={12}><Alert severity="error">{error}</Alert></Grid>}
      </Grid>

      {/* Password Change Section */}
      <Typography variant="h5" style={{ marginTop: '32px' }}>
        Change Password
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Current Password"
            type="password"
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="New Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Confirm New Password"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePasswordChange}
            disabled={passwordLoading}
          >
            {passwordLoading ? <CircularProgress size={24} /> : 'Change Password'}
          </Button>
        </Grid>
        {passwordError && <Grid item xs={12}><Alert severity="error">{passwordError}</Alert></Grid>}
        {passwordSuccess && <Grid item xs={12}><Alert severity="success">{passwordSuccess}</Alert></Grid>}
      </Grid>
    </Paper>
  );
};

export default MyAccount;

